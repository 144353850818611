/**
 * Returns a function that given two objects, sorts by an arbitrary key
 * @see Array.prototype.sort
 * @param key string name of sort-by key (default 'order')
 * @param reverse bool true to reverse sort order
 * @return number
 */
export function sortObjectsByKey(key = 'order', reverse=false) {
  return (a, b) => {
    let modifier = (reverse) ? -1 : 1;
    if (a[key] < b[key]) {
      return -1 * modifier;
    } else if (a[key] > b[key]) {
      return 1 * modifier;
    } else {
      return 0;
    }
  };
}

/**
 * Is it an object?
 */
export function isObject(obj) {
  return (typeof obj === "object") && (obj !== null);
}


export function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // Otherwise, does it have any properties of its own?
    if (Object.getOwnPropertyNames(obj).length > 0) return false;

    return true;
}

/**
 * Returns the value at the specified key, the default value, or undefined.
 * @param object {foo: { bar: { baz: 4 } } }
 * @param string "foo.bar.baz"
 * @param value default value if no value is found at that key.
 */
export function getKey(obj, keyStr, defaultValue = undefined) {
  let keys = keyStr.split('.');
  let ref = obj;
  for (let key of keys) {
    if (!isObject(ref)) {
      return defaultValue;
    }
    ref = ref[key];
  }
  return (ref === null || ref === undefined) ? defaultValue : ref;
}

/**
 * Return a new object minus the specified key
 * @see http://stackoverflow.com/a/37247604/506244
 */
export function removeKey (myObj, deleteKey) {
  return Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current];
      return result;
  }, {});
}

/**
 * Converts an indexed object into a Javascript array, useful as
 * javascript arrays can be sorted, filtered, and mapped.
 * @param idxObj object Firebase-style "array".
 * @param keyname string Mapped from the object item index to a member with
 *     this name. Default "key".
 * @return array
 */
export function indexedObjectToArray(idxObj, keyname = 'key') {
  if (isObject(idxObj)) {
    let itemsArr = [];
    Object.keys(idxObj).forEach((item) => {
      itemsArr.push(idxObj[item])
      // Preserve the key, which is often the ID for the object.
      itemsArr[itemsArr.length-1][keyname] = item;
    });
    return itemsArr;
  } else {
    throw new Error('Invalid Data: first argument not an object.');
  }
}

/**
 * Converts an array of objects to an indexed object of objects. Useful for
 * redux-style persistence when keys are unique.
 * @param arr array Javascript array of objects.
 * @param keyname string object item key, default "id" -- if key missing in object(s),
 *    an Error is thrown.
 * @return object
 */
export function arrayToIndexedObject(arr, keyname = 'id') {
  if (Array.isArray(arr)) {
    let idxObj = {};
    for (let item of arr) {
      if (item[keyname] === undefined || item[keyname] === null || item[keyname] === '') {
        throw new Error(`Invalid Data: object(s) missing id/key data, keyname: ${keyname}`);
      }
      idxObj[item[keyname]] = item;
    }
    return idxObj;
  } else {
    throw new Error('Invalid Data: first argument not an array.');
  }
}

/**
 * Returns a currency-formatted string from a number.
 */
export function toCurrencyString(value) {
  if (typeof value !== "number") {
    value = parseInt(value, 10);
  }
  value = value || 0;
  let prefix =  '';
  let symbol = '$';

  if (value < 0) {
    value = value * -1;
    prefix = '-';
  }
  return [prefix, symbol, value.toLocaleString()].join('');
}

/**
 * @see http://stackoverflow.com/a/7557433/506244
 */
export function isElementInViewport (el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}
