import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SiteWrapper from 'site/ui/SiteWrapper';

export function SiteContainer (props) {
  return <SiteWrapper {...props}/>
}

function mapStateToProps(state, ownProps) {
  return { sync: state.api.sync };
}

export default withRouter(connect(mapStateToProps)(SiteContainer));