// api actions
export const UPDATE_TIME_NOW = '@api/update_now';
export const API_UPDATED     = '@api/updated';
export const API_START       = '@api/start';
export const API_COMPLETE    = '@api/end';

// posts & karma actions
export const UPDATE_POSTS      = '@posts/update';
export const UPDATE_POST       = '@post/update';
export const DELETE_POST       = '@post/delete';
export const UPDATE_KARMA      = '@karma/delete';
export const UPDATE_KARMAS     = '@karmas/update';
export const UPDATE_POST_KARMA = '@post/karma/update';

// topics actions
export const UPDATE_TOPICS   = '@topics/update';

// users actions
export const UPDATE_USERS    = '@users/update';

// config actions
export const UPDATE_CONFIG   = '@config/update';

// form actions
export const EDIT_START      = '@form/edit/start';
export const EDIT_COMPLETE   = '@form/edit/end';
