import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Alert } from 'reactstrap';
import Gravatar from 'ui/Gravatar';


export default class PostKarmaControl extends React.Component {

  static propTypes = {
    karma_positive: PropTypes.number.isRequired,
    postId: PropTypes.number.isRequired
  };

 constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.hasKarma = this.hasKarma.bind(this);

    this.state = {
      ttOpen: false,
    };
  }

  hasKarma() {
    const { benefactors, karma_positive } = this.props;
    return (benefactors.length === 0 && karma_positive > 0);
  }

  toggle() {
    const { benefactors, karma_positive, fetchKarma } = this.props;

    this.setState({
      ttOpen: !this.state.ttOpen
    });
    if (this.hasKarma()) {
      fetchKarma();
    }
  }

  render() {
    const { postId, karma_positive, loading, benefactors, handleSubmitKarma } = this.props;
    const { ttOpen } = this.state;
    const ttTarget = `karma-control-${postId}`;

    const renderTooltip = (target, open, toggle, content) => {
      return (
        <Tooltip placement="top" target={target} isOpen={open} toggle={toggle}>
          { content }
        </Tooltip>
      );
    }

    let ttContent = (<span>No karma!</span>);

    if (benefactors.length > 0) {
      ttContent = (
        <div className="karma-tooltip">{
          benefactors.map((user) => {
            return (
              <span className="photo" key={`ktt_${postId}_${user.id}`}>
                <a className="karma-tooltip-user" href="#">
                  <Gravatar gravatar_id={ user.gravatar } altText={ user.username } size="30"/>
                </a>
              </span>
            );
          })
        }</div>
      );
    }
    else if (this.hasKarma()) {
      ttContent = (<span><i className="fa fa-2x fa-spin fa-spinner"/></span>);
    }

    const tooltip = renderTooltip(ttTarget, ttOpen, this.toggle, ttContent);

    const karma_button = loading ? (
      <a className="karma-action" href="#">
        <i className="fa fa-spinner fa-spin"/>
      </a>
    ) : (
      <a className="karma-action" onClick={handleSubmitKarma} href="#">
        <i className="fa fa-heart"/>
      </a>
    );

    return (
      <span id={ttTarget} className="post-action post-karma">
        <span className="karma-value value">{ karma_positive }</span>
        { karma_button }
        { tooltip }
      </span>
    );
  };

};