import React from 'react';

export default function CarouselPostNextArrow(props) {

  const {disabled} = props;

  const buttonNext = (
    <button className="carousel-btn next-arrow" onClick={props.onClick}>
      <i className="fa fa-2x fa-angle-right"/>
    </button>
  );
  const buttonNextDisabled = (
    <button className="carousel-btn next-arrow disabled" disabled="disabled">
      <i className="fa fa-2x fa-angle-right"/>
    </button>
  );
  const buttonSkip = (
    <button className="carousel-btn skip-arrow" onClick={props.skip}>
      <i className="fa fa-2x fa-angle-double-right"/>
    </button>
  );
  const buttonSkipDisabled = (
    <button className="carousel-btn skip-arrow disabled" disabled="disabled">
      <i className="fa fa-2x fa-angle-double-right"/>
    </button>
  );

  return (
    <div className="carousel-arrow-container">
      { disabled ? buttonNextDisabled : buttonNext }
      { disabled ? buttonSkipDisabled : buttonSkip }
    </div>
  );
}
