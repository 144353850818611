import React from 'react';


export default function (props) {
  const { size, gravatar_id, altText } = props;
  const imgStyle = {
    height: `${size}px`,
    width: `${size}px`
  }
  return (
    <img
      className="avatar"
      style={imgStyle}
      src={`https://www.gravatar.com/avatar/${gravatar_id}?s=96&d=retro`}
      alt={altText}
    />
  );
}
