import React from 'react';
import Gravatar from 'ui/Gravatar';
import { convertTZ } from 'lib/helpers';
import { POST_DATE_FORMAT, POST_TIME_FORMAT } from 'constants/index';


export default function DeletedPost (props) {

  const { post: { id, user, created_at }} = props;

  return (
    <div className="post deleted-post " id={"post-" + id}>
      <span className="message">
        — Post deleted <i className="fa fa-trash"/>{' '}
        { convertTZ(created_at, user.attributes.timezone).format(POST_TIME_FORMAT) }{' '}
        { convertTZ(created_at, user.attributes.timezone).format(POST_DATE_FORMAT) }
        {' '}—
      </span>
    </div>
  );
};
