import React from 'react';
import moment from 'moment-timezone';
import textile from 'textile-js';
//App Imports
import CarouselPostWrapper from 'carousel-post/ui/CarouselPostWrapper';
import Gravatar from 'ui/Gravatar';


export default class CarouselPost extends React.Component {

  rawMarkup(text) {
    return { __html: textile(text) };
  };

  render() {
    const { post, now } = this.props;
    const { user } = this.props.post;
    const last_update = moment.tz(post.updated_at, 'UTC').tz(post.user.attributes.timezone).from(now);
    const message = post.state === 'DELETED' ? textile('_[Post Deleted]_') : post.message;

    return (
      <CarouselPostWrapper>
        <div className="leftbar pull-left">
          <Gravatar gravatar_id={user.gravatar} size="48" />
        </div>

        <div className="body">
          <div className="inner">
            <div className="timestamp-ago">{last_update}</div>
            <div className="message" dangerouslySetInnerHTML={this.rawMarkup(message)} />
          </div>
        </div>
      </CarouselPostWrapper>
    );
  };
};
// <div className="emotion"/>
// <div className="reaction"/>
// <i className="fa fa-heart fa-fw fa-2x" />
