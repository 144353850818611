import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
// App Imports
import {configure} from 'store';
import DefaultRoutes from 'router/router';
import {
  updateTimeNow,
  fetchLaunchData,
  updateConfig,
  fetchUpdates
} from 'actions';

// App CSS
require('font-awesome/css/font-awesome.css');
require('app/styles/app.scss');

let state = {};

// localStorage.removeItem('state');

try {
  state = JSON.parse(localStorage.getItem('state')) || {};
} catch (err) {
  console.log(err);
}

let store = configure(state);

store.dispatch(updateConfig(window.config));

if (store.getState().config.auth.id) {

  if (store.getState().api.sync === false) {
    store.dispatch(fetchLaunchData());
  }

  store.subscribe(() => {
    const state = store.getState();
    let storedState = {
      ...state,
      app: undefined,
      form: undefined,
      api: {
        ...state.api,
        posting: {},
        loading: false
      }
    };
    localStorage.setItem('state', JSON.stringify(storedState));
  });

  setInterval(() => {
    store.dispatch(updateTimeNow());
  }, 60000); // one minute

  setInterval(() => {
    store.dispatch(fetchUpdates());
  }, 300000); // five minutes

}

const reactRootEl = document.getElementById('content');

if (reactRootEl) {
  ReactDOM.render(
    (
      <Provider store={store}>
        { DefaultRoutes }
      </Provider>
    ), reactRootEl
  );
}
