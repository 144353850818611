import React from 'react';


export default function CarouselPostWrapper(props) {
  return (
    <div className="carousel-post">
      {props.children}
    </div>
  );
};
