import React from 'react';
import { connect } from 'react-redux';
import { startSubmit, SubmissionError } from 'redux-form';
import PostForm from 'post/ui/CreatePostForm';
import { createPost as createPostAction } from 'actions';
import * as str from 'constants/strings';


const CreatePostForm = ({ createPost, startSubmit }) => {

  const handleSubmit = values => {
    startSubmit(str.FORM_POST_CREATE);
    return createPost(values.message)
      .catch(error => {
        throw new SubmissionError({ _error: str.FORM_POST_ERROR })
    });
  };

  return <PostForm onSubmit={handleSubmit} />;
};


function mapDispatchToProps(dispatch, props) {
  return {
    startSubmit: form_name => dispatch(startSubmit(form_name)),
    createPost: message => dispatch(createPostAction(props.topicId, message))
  }
}


export default connect(undefined, mapDispatchToProps)(CreatePostForm);
