import React from 'react';
import { connect } from 'react-redux';
import { startSubmit, SubmissionError } from 'redux-form';
import PostForm from 'post/ui/UpdatePostForm';
import { editPost } from 'actions';
import * as str from 'constants/strings';


const UpdatePostForm = ({ post, cancel, startSubmit, editPost }) => {

  const handleSubmit = values => {
    startSubmit(str.FORM_POST_UPDATE);
    if (post.message !== values.message) {
      return editPost(values.message).catch(error => {
        throw new SubmissionError({ _error: str.FORM_POST_ERROR })
      });;
    }
  }

  return (
    <PostForm
      initialValues={ { message: post.message } }
      onSubmit={ handleSubmit }
      cancel={ e => { e.preventDefault(); cancel() } }
    />
  );
};


function mapDispatchToProps(dispatch, props) {
  return {
    startSubmit: form_name => dispatch(startSubmit(form_name)),
    editPost: message => dispatch(editPost(props.post.id, message))
  }
}

export default connect(undefined, mapDispatchToProps)(UpdatePostForm);
