import React from 'react';
import CarouselPostContainer from 'carousel-post/data/CarouselPostContainer';
import CarouselPostPrevArrow from 'carousel-post/ui/CarouselPostPrevArrow';
import CarouselPostNextArrow from 'carousel-post/ui/CarouselPostNextArrow';
import CarouselPostWrapper from 'carousel-post/ui/CarouselPostWrapper';


const BUTTON_PRESS_NONE = 0;
const BUTTON_PRESS_PREV = 1;
const BUTTON_PRESS_NEXT = 2;
const BUTTON_PRESS_SKIP = 3;

export default class CarouselPostList extends React.Component {

  constructor(props) {
    super(props);

    this.prevPost = this.prevPost.bind(this);
    this.nextPost = this.nextPost.bind(this);
    this.mostCurrentPost = this.mostCurrentPost.bind(this);
    // this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);

    this.state = {
      currentIndex: 0,
      currentId: props.posts.length > 0 ? props.posts[0].id : 0,
      lastButtonPress: BUTTON_PRESS_NONE,
      loading: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { posts } = nextProps;
    const currentPosts = this.props.posts;
    const { currentIndex, currentId, lastButtonPress } = this.state;
    let nextIndex;

    if (posts.length !== currentPosts.length) {
      if ((lastButtonPress === BUTTON_PRESS_PREV) && currentIndex === 0) {
        nextIndex = 1;
      }
      else {
        let i = 0;
        // Find the post with the currentId in the posts array
        while ((posts[i].id !== currentId) && (i < posts.length-1)) i++;
        // If currentIndex was 0 and i is not 0, we just added new posts; bump up index.
        nextIndex = (currentIndex === 0 && i > 0) ? i - 1 : i;
      }
      this.setState({
        currentIndex: nextIndex,
        currentId: posts[nextIndex].id,
        loading: false
      });
    }
  }

  prevPost(e) {
    const {posts, fetchEarlierPosts} = this.props;
    const {currentIndex} = this.state;
    const nextIndex = currentIndex + 1;

    if (nextIndex < posts.length) {
      this.setState({
        currentIndex: nextIndex,
        currentId: posts[nextIndex].id,
        lastButtonPress: BUTTON_PRESS_PREV
      });
    }
    if (nextIndex >= posts.length-1) {
      fetchEarlierPosts();
      this.setState({
        loading: true,
        lastButtonPress: BUTTON_PRESS_PREV
      });
    }
  }

  nextPost(e) {
    const {posts} = this.props;
    const {currentIndex} = this.state;
    const nextIndex = currentIndex - 1;

    if (nextIndex >= 0) {
      this.setState({
        currentIndex: nextIndex,
        currentId: posts[nextIndex].id,
        lastButtonPress: BUTTON_PRESS_NEXT
      });
    }
  }

  mostCurrentPost(e) {
    const {posts} = this.props;
    const currentIndex = 0;

    this.setState({
      currentIndex,
      currentId: posts[currentIndex].id,
      lastButtonPress: BUTTON_PRESS_NEXT
    });
  }

  render() {
    const {posts} = this.props;
    const {currentIndex, loading} = this.state;

    const disabled = (0 === currentIndex);

    let currentPost = (<div>Nothing</div>);

    if (posts.length === 0) {
      currentPost = (
        <CarouselPostWrapper>
          No posts to display!
        </CarouselPostWrapper>
      );
    } else if (currentIndex < 0 || currentIndex >= posts.length) {
      currentPost = (
        <CarouselPostWrapper>
          Error: Index Out of Range.
        </CarouselPostWrapper>
      );
    } else {
      currentPost = (
        <CarouselPostContainer post={ posts[currentIndex] } key={ posts[currentIndex].id } />
      );
    }

    return (
      <div className="carousel-post-list">
        { currentPost }
        <CarouselPostPrevArrow onClick={this.prevPost} numPosts={currentIndex} loading={loading} disabled={posts.length===0}/>
        <CarouselPostNextArrow onClick={this.nextPost} skip={this.mostCurrentPost} disabled={disabled}/>
      </div>
    );
  }
};
