import React from 'react';
import Topic from 'ui/Topic';


export default class TopicList extends React.Component {

  render() {
    const { topics } = this.props;

    const noTopics = (
      <span className="empty">
        <em>You are not a part of any chats! Start a conversation by naming it and adding users.</em>
      </span>
    );

    const topicNodes = (topics.length === 0) ? noTopics :
      topics.map(function(topic) {
        return (
          <Topic topic={ topic } key={ topic.id }/>
        );
      });

    return (
      <div className="topicList">
        { topicNodes }
      </div>
    );
  }
};

