import React from 'react';
import {connect} from 'react-redux';
import SiteNav from 'site/ui/SiteNav';


export function SiteNavContainer(props) {
  return <SiteNav {...props}/>;
}


function mapStateToProps(state, props) {
  const { site } = state.config;
  const user = state.users[state.config.auth.id];

  return { user, site };
}

export default connect(mapStateToProps)(SiteNavContainer);