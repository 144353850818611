import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Gravatar from 'ui/Gravatar';
import * as str from 'constants/strings';


export default class SiteNav extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  render() {
    const { user, site } = this.props;

    return (
      <div className="site-nav">
        <a id="top"/>
        <div className="bd inline-clearfix">

          <div className="logo pull-left">
            <Link to={`/topics/`}>
              <img src={ site.logo } />
            </Link>
          </div>

          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="pull-right user-menu">
            <DropdownToggle caret id="userNavBtn">
              <Gravatar gravatar_id={user.gravatar} size="30"/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href={`/user/${user.username}`}>
                <i className="fa fa-pencil fa-fw"/> { str.LINK_TEXT_SETTINGS }
              </DropdownItem>
              <DropdownItem tag="a" href='/logout'>
                <i className="fa fa-sign-out fa-fw"/> { str.LINK_TEXT_LOGOUT }
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="ft"/>
      </div>
    );
  };
};