import React from 'react';
import {connect} from 'react-redux';
import {fetchUpdates} from 'actions';
import {getAPIStatus} from 'api/hyper-redux';
import TopicListContainer from 'data/TopicListContainer';
import TopicsNavBar from 'ui/TopicsNavBar';
import FooContainer from 'ui/FooContainer';
import { make_title } from 'lib/helpers';


export class TopicsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  componentDidMount() {
    const { site: { title, env } } = this.props;
    document.title = make_title(title, '', env);
    this.handleRefresh();
  };

  handleRefresh(e) {
    const { fetchUpdates } = this.props;
    fetchUpdates();
  }

  render() {
    return (
      <div>
        <TopicsNavBar loading={this.props.loading} refresh={this.handleRefresh} />
        <TopicListContainer />
      </div>
    );
  }
};


function mapStateToProps(state, props) {
  const { site } = state.config;
  return {
    site,
    loading: getAPIStatus('fetch_updates')
  };
}

export default connect(mapStateToProps, { fetchUpdates })(TopicsContainer);
