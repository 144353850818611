import React from 'react';
import moment from 'moment';
import textile from 'textile-js';
import PostHeaderContainer from 'post/data/PostHeaderContainer';
import { convertTZ } from 'lib/helpers';
import { POST_DATE_FORMAT, POST_TIME_FORMAT } from 'constants/index';


export default class Post extends React.Component {

  render() {
    const { post, auth, handleEdit, handleDelete } = this.props;
    const edited = post.state === 'UPDATED';

    return (
      <div className="post user-post " id={"post-" + post.id}>
        <PostHeaderContainer post={post} handleEdit={handleEdit} handleDelete={handleDelete}/>
        <div className="message p-1" dangerouslySetInnerHTML={{__html: textile(post.message)}} />
        { edited ? <div className="edited">— Edited —</div> : '' }
      </div>
    );
  };
};