import React from 'react';

export default (props) => {
  const { icon, label, desc, disabled, onClick } = props;

  const disabledClass = (disabled || !onClick) ? 'disabled' : '';
  const nullClick = (e) => e.preventDefault();

  const clickHandler = (!disabled) ? (onClick || nullClick) : nullClick;

  return (
    <span className={"post-action " + disabledClass}>
      <a title={desc} onClick={clickHandler} href="#">
        <i className={"fa " + icon}/>
        {' '}
        <span className="label">{label}</span>
      </a>
    </span>
  );
}
