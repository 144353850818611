import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {editStart, editComplete} from 'actions';
import {getKey} from 'utils/index';
import Post from 'post/ui/Post';
import DeletePost from 'post/data/DeletePostControl';
import EditPost from 'post/ui/EditPost';
import DeletedPost from 'post/ui/DeletedPost';


export class PostContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.cancel = this.cancel.bind(this);

    this.state = {
      deleting: false,
      editing: false
    };
  }

  handleEdit(e) {
    const {editStart} = this.props;
    e.preventDefault();
    editStart();
    this.setState(() => ({ deleting: false }));
  }

  handleDelete(e) {
    const { post } = this.props;
    e.preventDefault();
    this.setState(() => ({ deleting: true }));
  };

  cancel() {
    const {editComplete} = this.props;
    editComplete();
    this.setState(() => ({ deleting: false }));
  }

  render() {
    const { post, editing } = this.props;
    const { deleting } = this.state;
    let postComponent;

    if (editing && editing === post.id) {
      postComponent = <EditPost post={post} cancel={this.cancel} />;
    }
    else if (deleting) {
      postComponent = <DeletePost post={post} cancel={this.cancel} />;
    }
    else if (post.state === 'DELETED') {
      postComponent = <DeletedPost post={post}/>;
    }
    else {
      postComponent = (
        <Post
          post={post}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
        />
      );
    }

    return postComponent;
  }
};

const mapStateToProps = (state, ownProps) => {
  return {editing: state.app.editing};
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const {id} = ownProps.post;
  return {
    editStart: () => {dispatch(editStart(id));},
    editComplete: () => {dispatch(editComplete(id));}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostContainer);