import {combineReducers} from 'redux';
import {handleActions} from 'redux-actions';
import {reducer as formReducer} from 'redux-form';
import * as ACTIONS from 'action_types';
import { arrayToIndexedObject, getKey, removeKey } from 'app/utils';
import { postsToAudited } from 'lib/helpers';


// function createSimpleReducer(action, defaultState) {
//   return handleActions({
//     [action]: (state, action) => {
//       return action.payload;
//     }
//   }, defaultState);
// }

// export const bets = createSimpleReducer(actions.updateBetsData, {});

/**
 * topics Reducer - updating and storing topic data
 */
export const topics = (state = [], action) => {

  switch(action.type) {
    case ACTIONS.UPDATE_TOPICS: {
      const topics = arrayToIndexedObject(action.payload);
      return {
        ...state,
        ...topics
      }
    }
    default:
      return state;
  };
};

/**
 * user Reducer - updating and storing topic data
 */
export const users = (state = {}, action) => {

  switch(action.type) {
    case ACTIONS.UPDATE_TOPICS: {
      const topics = action.payload;
      let newState = {...state};
      for (let topic of topics) {
        if (topic.last_post) {
          newState[topic.last_post.user.id] = topic.last_post.user;
        }
      }
      return newState;
    };
    case ACTIONS.UPDATE_USERS: {
      const users = arrayToIndexedObject(action.payload);
      return {
        ...state,
        ...users
      };
    };
    default:
      return state;
  };
};

/**
 * posts Reducer
 *
 * posts are stored as an object, indexed by the string version of post.id
 * e.g { "1": {...post 1 data...}, "2": {...post 1 data...}}
 */
export const posts = (state = {}, action) => {

  switch(action.type) {
    case ACTIONS.UPDATE_TOPICS: {
      const topics = action.payload;
      let newState = {...state};
      for (const topic of topics) {
        if (topic.last_post) {
          const lp = topic.last_post;
          newState[lp.id] = {
            ...newState[lp.id],
            ...lp
          }
        }
      }
      return newState;
    }
    case ACTIONS.UPDATE_POSTS: {
      const posts = postsToAudited(action.payload);
      let newState = { ...state };
      for (const id in posts) {
        newState[id] = {
          ...newState[id],
          ...posts[id]
        };
      }
      return newState;
    }
    case ACTIONS.UPDATE_POST: {
      const post = action.payload;
      return {
        ...state,
        [post.id]: post
      };
    }
    case ACTIONS.DELETE_POST: {
      const postId = action.payload;
      return removeKey(state, postId.toString());
    }
    case ACTIONS.UPDATE_POST_KARMA: {
      const {postId, karmas} = action.payload;
      const karma_positive = karmas.reduce((acc, karma) => { return acc + karma.positive; }, 0);
      return {
        ...state,
        [postId]: {
          ...state[postId],
          karma_positive
        }
      };
    };
    default:
      return state;
  };
};

/**
 *
 */
export const karma = (state = {}, action) => {
  switch(action.type) {
    case ACTIONS.UPDATE_KARMA: {
      const karma = action.payload;
      return {
        ...state,
        [karma.id]: karma
      }
    }
    case ACTIONS.UPDATE_KARMAS: {
      const karmas = arrayToIndexedObject(action.payload);
      return {
        ...state,
        ...karmas
      }
    }
    default:
      return state;
  };
};


/**
 *
 */
const posts_to_karma = (state = {}, action) => {
  switch(action.type) {
    case ACTIONS.UPDATE_KARMA: {
      const { id, post_id } = action.payload.karma;
      return {
        ...state,
        [post_id]: Array.from(new Set(state[post_id]).add(id))
      }
    };
    case ACTIONS.UPDATE_KARMAS: {
      let newState = {...state};
      for (let karma of action.payload) {
        const { id, post_id } = karma;
        newState[post_id] = [...new Set(newState[post_id]).add(karma.id)];
      }
      return newState;
    }
    default:
      return state;
  };
};


/**
 *
 */
export const mapping = combineReducers({
  posts_to_karma
});


export const api = (state = {
  sync: false,    // State updated from server at least once
  status: {},    // Data is being read from or written to the server
  updatedAt: undefined // timestamp of most recent update
}, action) => {

  switch(action.type) {
    case ACTIONS.API_UPDATED: {
      return {
        ...state,
        sync: true,
        updatedAt: action.payload
      }
    };
    case ACTIONS.API_START: {
      const { category, id } = action.payload;
      const { status } = state;
      return {
        ...state,
        status: {
          ...status,
          [category]: {
            ...status[category],
            [id]: true
          }
        }
      };
    };
    case ACTIONS.API_COMPLETE: {
      const { category, id } = action.payload;
      const { status } = state;
      return {
        ...state,
        status: {
          ...status,
          [category]: {
            ...status[category],
            [id]: undefined
          }
        }
      };
    };
    default:
      return state;
  };
};


export const app = (state={ now: Date.now() }, action) => {
  switch(action.type) {
    case ACTIONS.EDIT_START: {
      return {
        ...state,
        editing: action.payload
      }
    };
    case ACTIONS.EDIT_COMPLETE: {
      return {
        ...state,
        editing: undefined
      }
    };
    case ACTIONS.UPDATE_TIME_NOW:
    default:
      return {
        ...state,
        now: Date.now()
      };
  };
}


export const config = (state = {}, action) => {

  switch(action.type) {
    case ACTIONS.UPDATE_CONFIG: {
      return {
        ...state.config,
        ...action.payload
      }
    };
    default:
      return state;
  };
};


export const form = formReducer.plugin({});

