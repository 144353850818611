import React from 'react';
import { Tooltip } from 'reactstrap';
import Gravatar from 'ui/Gravatar';


export default class TopicMembersUser extends React.Component {

 constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { user } = this.props;

    const tooltip_target = `topic-members-user-${user.id}`;
    const tooltip_delay = {
      show: 0,
      hide: 100
    };

    const user_profile_href = `/user/${user.username}`;

    return (
      <span className="user">
        <span className="vcard data">
          <span className="field photo url value">
            <a href={ user_profile_href }
               title={ user.username }
               className="user-link"
               id={ tooltip_target }
            >
              <Gravatar gravatar_id={ user.gravatar } altText={ user.username } size="36"/>
            </a>
          </span>
          <Tooltip placement="top" delay={tooltip_delay}
            isOpen={this.state.tooltipOpen} toggle={this.toggle}
            target={ tooltip_target }>
            { user.username }
          </Tooltip>
        </span>
      </span>
    );
  };

};
