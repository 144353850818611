import React from 'react';
// import Slider from 'react-slick';
import {connect} from 'react-redux';
// App Imports
import {filterPostsByTopicId, getAPIStatus} from 'app/api/hyper-redux';
import {fetchUpdates, fetchPosts} from 'actions';
import { make_title } from 'lib/helpers';
import PostsPage from 'posts/ui/PostsPage';
import { POSTS_NUM_TO_LOAD } from 'constants/index';


export class PostsPageContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { topic, posts, fetchPosts, refresh } = this.props;
    const { site: { title, env } } = this.props;

    document.title = make_title(title, topic.name, env);

    if (posts.length < POSTS_NUM_TO_LOAD && posts.length !== 0) {
      fetchPosts(topic.id, posts[posts.length-1].created_at, POSTS_NUM_TO_LOAD);
    }
    refresh();
  }

  render() {
    return <PostsPage {...this.props} />;
  }

};


function mapStateToProps(state, props) {
  const topic = state.topics[props.match.params.topicId];
  const { site } = state.config;
  return {
    site,
    topic,
    posts: filterPostsByTopicId(state.posts, topic.id),
    refreshing: getAPIStatus('fetch_updates'),
    loading: getAPIStatus('fetch_posts', topic.id)
  }
}

function mapDispatchToProps(dispatch, props) {
  const {topic, posts} = props;
  return {
    refresh: () => { dispatch(fetchUpdates()) },
    fetchPosts: (topicId, date, count) => { dispatch(fetchPosts(topicId, date, count)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsPageContainer);
