import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitKarma, fetchKarmaForPost } from 'actions';
import { getAPIStatus, getPostBenefactors } from 'api/hyper-redux';
import PostKarmaControl from 'post/ui/PostKarmaControl';


export class PostKarmaContainer extends React.Component {

  static propTypes = {
    postId: PropTypes.number.isRequired,
    karma_positive: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired
  };

 constructor(props) {
    super(props);
    this.handleSubmitKarma = this.handleSubmitKarma.bind(this);
    this.fetchKarma = this.fetchKarma.bind(this);
  }

  handleSubmitKarma(e) {
    const { dispatch, postId } = this.props;
    e.preventDefault();
    dispatch(submitKarma(postId));
  }

  fetchKarma() {
    const { dispatch, postId } = this.props;
    dispatch(fetchKarmaForPost(postId));
  }

  render() {
    return (
      <PostKarmaControl {...this.props}
        handleSubmitKarma={this.handleSubmitKarma}
        fetchKarma={this.fetchKarma}
      />
    );
  };

};

function select(state, ownProps) {
  const { postId } = ownProps;
  return {
    benefactors: getPostBenefactors(postId),
    loading: getAPIStatus('post_karma', postId)
  };
}

export default connect(select)(PostKarmaContainer);