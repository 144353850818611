import React from 'react';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse
} from 'reactstrap';

const TEXT_POSTS = 'Posts';
const TEXT_SEARCH = 'Search';
const TEXT_USERS = 'Users';


export default class PostsPageNav extends React.Component {

  constructor(props) {
    super(props);

    this.handleRefresh = this.handleRefresh.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  handleRefresh(e) {
    e.preventDefault();
    this.props.refresh();
  }

  null(e) {
    e.preventDefault();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const {topic, loading} = this.props;

    return (
      <Navbar expand="md">
        <NavbarBrand onClick={this.handleRefresh} href={`/topics/${topic.id}/posts`}>
          { topic.name }
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle}>
          <i className="fa fa-bars"/>
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink className="active" onClick={this.handleRefresh}
                href={ `/topics/${topic.id}/posts` }>{TEXT_POSTS}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={`/topics/${topic.id}/search`}>{TEXT_SEARCH}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={`/topics/${topic.id}/users`}>{TEXT_USERS}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={loading ? this.null : this.handleRefresh}>
                <i className={"fa fa-fw fa-refresh " + (loading ? "fa-spin" : "")}/>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  };
};