import {combineReducers, createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
// App imports
import * as reducers from 'reducers';
// import {reducer as formReducer} from 'redux-form';

let store;

export function getStore() {
  if (store === undefined) {
    throw new Error('You must call "configure()" before calling getStore!');
  }
  return store;
};

export function configure(initialState = {}) {
  const reducer = combineReducers({
    ...reducers,
    // form: formReducer
  });

  const middleware = compose(
    applyMiddleware(thunk),
    (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__)
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  );

  store = createStore(reducer, initialState, middleware);
  return getStore();
};
