import React from 'react';
import {connect} from 'react-redux';
import SiteLoading from 'site/ui/SiteLoading';
import SiteNavContainer from 'site/data/SiteNavContainer';
import SiteFooter from 'site/ui/SiteFooter';


export function SiteWrapper(props) {

  if (!props.sync) {
    return <SiteLoading/>;
  }
  return (
    <div>
      <SiteNavContainer/>
      { props.children }
      <SiteFooter/>
    </div>
  );
};

// @TODO what's up with this? looks redundant given SiteContainer
export default connect((state) => {
  return {
    sync: state.api.sync
  }
})(SiteWrapper);
