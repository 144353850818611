import React from 'react';

export default function CarouselPostPrevArrow(props) {
  const copy = (props.numPosts > 0) ?
    (<div className="copy">{props.numPosts}</div>) :
    "";

  const buttonLoading = (
    <button className="carousel-btn prev-arrow disabled" disabled="disabled">
      <i className="fa fa-2x fa-refresh fa-spin"/>
    </button>
  );

  const buttonPrev = (
    <button className="carousel-btn prev-arrow" onClick={props.onClick} disabled={props.disabled}>
      <i className="fa fa-2x fa-angle-left"/>
    </button>
  );

  const button = (props.loading) ? buttonLoading : buttonPrev;

  return (
    <div className="carousel-arrow-container">
      {button}
      <div className="remaining">
        {copy}
      </div>
    </div>
  );
};
