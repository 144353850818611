

export function createAPIStatusKey(category, id='None') {
  return { category, id };
}


export function postErrorHandler(error, fn, dispatch, getState) {
  restError(error, fn, dispatch, getState);
};


export function restError(error, fn, dispatch, getState) {
  if (error.response) {
    if (error.response.status === 401) {
      window.location = '/login';
    }
  } else {
    throw error;
  }
}