import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import {Field} from 'redux-form';
import {Button} from 'reactstrap';
import * as str from 'constants/strings';


export default class PostForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  }

  renderTextarea({ input, label, type, meta: { touched, error, warning, submitting } }) {
    return (
      <div>
        <TextareaAutosize {...input}
          disabled={submitting} placeholder={str.POST_PLACEHOLDER}/>
        {
          touched &&
            ((error && <span className="error">{error}</span>) ||
              (warning && <span className="warning">{warning}</span>))
        }
      </div>
    );
  }

  render() {
    const { cancel, pristine, submitting, initialValues, error } = this.props;

    const editing = !!(initialValues && initialValues.message);

    const button_cancel = (editing) ? <Button onClick={cancel} className="cancel" disabled={submitting}>Cancel</Button> : '';
    const button_text_value = (editing) ? str.POST_BUTTON_EDIT : str.POST_BUTTON_CREATE;
    const button_text = (submitting) ? <i className="fa fa-fw fa-pulse fa-spinner"/> : button_text_value;

    return (
      <div className="p-2">
        <form onSubmit={this.handleSubmit}>
          <div className="p-2">
            <Field name="message" component={this.renderTextarea}/>
          </div>
          <div className="d-flex justify-content-between flex-row p-2">
            <div className="action">
              <Button type="submit" color="primary" disabled={pristine || submitting}>{button_text}</Button>
            </div>
            <div className="">
              { button_cancel }
            </div>
          </div>
        </form>
        { error && <span className="error">{error}</span> }
      </div>
    );
  };
}


