import React from 'react';
import PostAction from 'post/ui/PostAction';
import PostKarmaContainer from 'post/data/PostKarmaContainer';
import Gravatar from 'ui/Gravatar';
import { convertTZ } from 'lib/helpers';
import { POST_DATE_FORMAT, POST_TIME_FORMAT } from 'constants/index';


export default class PostHeader extends React.Component {

  render() {

    const { post, user, auth, handleEdit, handleDelete, editing } = this.props;

    const user_profile_href = `/user/${user.username}`;

    const edit = (post.user.id === auth.id) ?
      (
        <span>
          <PostAction label="edit" icon="fa-pencil" desc={"Edit post #" + post.id} onClick={handleEdit} disabled={editing}/>{' '}
          <PostAction label="delete" icon="fa-trash" desc={"Delete post #" + post.id} onClick={handleDelete} disabled={editing}/>
        </span>
      ) : (
        <span />
      );

    return (
      <div className="hd container">
        <div className="row align-items-top">
          <div className="col-8 col-md-6">
            <span className="user">
              <span className="field photo url value">
                <a href={ user_profile_href }
                   title={ user.username }
                   className="user-link"
                >
                  <Gravatar gravatar_id={ user.gravatar } altText={ user.username } size="40"/>
                </a>
              </span>
              <span className="field fn nickname url value">
                <a href={ user_profile_href }
                   title={`View ${user.username }'s profile.`}>{ user.username }</a>
              </span>
              <span className="field date">
                <span className="created value">
                  <span className="time">
                    { convertTZ(post.created_at, user.attributes.timezone).format(POST_TIME_FORMAT) }

                  </span>{' '}
                  <span className="date">
                    { convertTZ(post.created_at, user.attributes.timezone).format(POST_DATE_FORMAT) }
                  </span>
                </span>
              </span>
            </span>
          </div>

          <div className="col-4 col-md-6 col-right">
            <div className="post-actions">
              { edit }
              <PostKarmaContainer postId={ post.id } karma_positive={ post.karma_positive } />
            </div>
            <div className="identifier">
              <a href="#">#{ post.id }</a>
            </div>
          </div>
        </div>
      </div>
    );
  };
};