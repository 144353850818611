import React from 'react';
import { connect } from 'react-redux';
import DeletePostControl from 'post/ui/DeletePostControl';
import { submitDeletePost } from 'actions';


function mapDispatchToProps(dispatch) {
  return {
    onDelete: id => { dispatch(submitDeletePost(id)); }
  };
}

export default connect(undefined, mapDispatchToProps)(DeletePostControl);