import React from 'react';
import textile from 'textile-js';
import PostHeaderContainer from 'post/data/PostHeaderContainer';
import PostForm from 'post/data/UpdatePostForm';


export default class EditPost extends React.Component {

  render() {

    const { post, cancel } = this.props;

    return (
      <div className="post user-post" id={ "post-" + post.id }>
        <PostHeaderContainer post={post}/>
        <div className="bd">
          <span className="post-form edit">
            <PostForm post={post} cancel={cancel} />
          </span>
        </div>
      </div>
    );
  };
};