import { createAction } from 'redux-actions';
import * as ACTIONS from 'action_types';
import * as api from 'api/hyper-rest';
import { postErrorHandler, createAPIStatusKey, restError } from 'redux/common';
import { POSTS_NUM_TO_LOAD } from 'constants/index';

// api actions
export const updateTimeNow = createAction(ACTIONS.UPDATE_TIME_NOW);
export const apiUpdated = createAction(ACTIONS.API_UPDATED);
export const apiStart = createAction(ACTIONS.API_START);
export const apiComplete = createAction(ACTIONS.API_COMPLETE);

// posts actions
export const updatePost = createAction(ACTIONS.UPDATE_POST);
export const deletePost = createAction(ACTIONS.DELETE_POST);
export const updatePosts = createAction(ACTIONS.UPDATE_POSTS);
export const updatePostKarma = createAction(ACTIONS.UPDATE_POST_KARMA);
export const updateKarmas = createAction(ACTIONS.UPDATE_KARMAS);

// topics actions
export const updateTopics = createAction(ACTIONS.UPDATE_TOPICS);

// users actions
export const updateUsers = createAction(ACTIONS.UPDATE_USERS);

// config actions
export const updateConfig = createAction(ACTIONS.UPDATE_CONFIG);

// form actions
export const editStart = createAction(ACTIONS.EDIT_START);
export const editComplete = createAction(ACTIONS.EDIT_COMPLETE);


export const fetchLaunchData = () => {
  return (dispatch, getState) => {
    const key = createAPIStatusKey('fetch_launch_data');
    dispatch(apiStart(key));
    api.fetchLaunchData()
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(updateTopics(resp.data.topics));
          dispatch(updatePosts(resp.data.posts));
          dispatch(updateUsers(resp.data.users));
          dispatch(apiUpdated(resp.data.updatedAt));
        } else {
          restError(resp.error)
        }
      })
      .catch((error) => {
        restError(error)
      })
      .then(() => {
        dispatch(apiComplete(key));
      });
  }
};

export const fetchUpdates = () => {
  return (dispatch, getState) => {
    const key = createAPIStatusKey('fetch_updates');
    dispatch(apiStart(key));
    const timestamp = getState().api.updatedAt;
    api.fetchUpdatesSince(timestamp)
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(updatePosts(resp.data.posts));
          dispatch(updateTopics(resp.data.topics));
          dispatch(apiUpdated(resp.data.updatedAt));
        } else {
          restError(resp.error)
        }
      })
      .catch((error) => {
        restError(error)
      })
      .then(() => {
        dispatch(apiComplete(key));
      });
  }
};


export const fetchTopics = () => {
  return (dispatch, getState) => {
    const key = createAPIStatusKey('fetch_topics');
    dispatch(apiStart(key));
    api.fetchTopics()
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(updateTopics(resp.data));
        } else {
          restError(error)
        }
      })
      .catch((error) => {
        restError(error)
      })
      .then(() => {
        dispatch(apiComplete(key));
      });
  }
};

export const fetchPosts = (topicId, before, count=POSTS_NUM_TO_LOAD) => {
  return (dispatch, getState) => {
    const key = createAPIStatusKey('fetch_posts', topicId);
    dispatch(apiStart(key));
    api.fetchPosts(topicId, before, count)
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(updatePosts(resp.data));
        }
      })
      .catch((error) => {
        restError(error)
      })
      .then(() => {
        dispatch(apiComplete(key));
      });
  }
};

export const createPost = (topicId, message) => {
  return (dispatch, getState) => {
    return api.createPost(topicId, message)
      .then(
        request => {
          const resp = request.data;
          if (resp.success) {
            dispatch(updatePost(resp.data));
          }
        }
      );
  };
};

export const editPost = (postId, message) => {
  return (dispatch, getState) => {
    return api.updatePost(postId, message)
      .then(
        request => {
          const resp = request.data;
          if (resp.success) {
            dispatch(updatePost(resp.data));
            dispatch(editComplete(postId));
          }
        }
      );
  };
};

export const submitDeletePost = (postId, message) => {
  return (dispatch, getState) => {
    api.deletePost(postId, message)
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(deletePost(resp.data));
        }
      })
      .catch((error) => {
        postErrorHandler(error, deletePost(topicId, message), dispatch, getState);
      });

  };
};

export const submitKarma = (postId) => {
  return (dispatch, getState) => {
    const key = createAPIStatusKey('post_karma', postId);
    dispatch(apiStart(key));
    api.addKarma(postId)
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(updatePostKarma(resp.data));
          dispatch(updateKarmas(resp.data.karmas));
        }
      })
      .catch((error) => {
        postErrorHandler(error, submitKarma(postId), dispatch, getState);
      })
      .then(() => {
        dispatch(apiComplete(key));
      });
  };
};

export const fetchKarmaForPost = (postId) => {
  return (dispatch, getState) => {
    const key = createAPIStatusKey('fetch_karma', postId);
    dispatch(apiStart(key));
    api.fetchKarmaForPost(postId)
      .then((request) => {
        const resp = request.data;
        if (resp.success) {
          dispatch(updatePostKarma(resp.data));
          dispatch(updateKarmas(resp.data.karmas));
        }
      })
      .catch((error) => {
        postErrorHandler(error, fetchKarmaForPost(postId), dispatch, getState);
      })
      .then(() => {
        dispatch(apiComplete(key));
      });
  };
};