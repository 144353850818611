import React from 'react';
import PostContainer from 'post/data/PostContainer';


export default class PostsList extends React.Component {

  render() {
    const { topic, posts } = this.props;

    const postsList = posts.map((post) => {
      return (<PostContainer post={ post } key={ post.id }/>);
    });

    return (
      <span id="post-list" className="post-list">
        { postsList }
      </span>
    );
  };
};