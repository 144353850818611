import React from 'react';
import TopicMembersUser from 'posts/ui/TopicMembersUser';


export default class TopicMembers extends React.Component {

  render() {
    const { topic } = this.props;

    const members = topic.members.map((user) => {
      return (
        <TopicMembersUser key={ `topic-members-user-${user.id}` } user={ user } />
      );
    });

    return (
      <div className="right-sidebar user-list">
        <div className="hd">
          <span className="title">
            People in this Chat
          </span>
        </div>
        <div className="bd">
          { members }
        </div>
      </div>
    );
  };
};
