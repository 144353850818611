import React from 'react';
// import Slider from 'react-slick';
import {connect} from 'react-redux';
// App Imports
import {filterPostsByTopicId} from 'app/api/hyper-redux';
import {fetchPosts} from 'actions';
import CarouselPostList from 'carousel-post/ui/CarouselPostList';


export class CarouselPostListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.fetchEarlierPosts = this.fetchEarlierPosts.bind(this);
  }


  fetchEarlierPosts() {
    const {posts, topicId, dispatch} = this.props;
    dispatch(fetchPosts(topicId, posts[posts.length-1].created_at));
  }

  render() {
    const {posts} = this.props;

    return (
      <div>
        <CarouselPostList posts={posts} fetchEarlierPosts={this.fetchEarlierPosts} />
      </div>
    );
  }
};


function select(store, ownProps) {
  return {
    posts: filterPostsByTopicId(store.posts, ownProps.topicId)
  }
}

export default connect(select)(CarouselPostListContainer);
// @TODO TODO TODO
// Need to create a posts api to filter posts by topic
// Need to hook this page into that api to get a list of posts
// Need to hook the carousel into fetching the next few posts when it gets to
// the end of the line.