
export const FORM_POST_CREATE = 'create_post';
export const FORM_POST_UPDATE = 'edit_post';
export const FORM_POST_ERROR = 'Temporary error posting message. Please try again.';

export const POST_PLACEHOLDER = 'Type your message...';
export const POST_BUTTON_EDIT = 'Edit';
export const POST_BUTTON_CREATE = 'Post!';

export const LINK_TEXT_LOGO = 'LesChats';
export const LINK_TEXT_SETTINGS = 'settings';
export const LINK_TEXT_LOGOUT = 'sign out';