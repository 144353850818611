import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import Post from 'post/ui/Post';


export default class DeletePostControl extends React.Component {

  constructor(props) {
    super(props);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.state = {
      submitting: false
    };
  }

  handleConfirm(e){
    const { post, onDelete } = this.props;
    e.preventDefault();
    this.setState({submitting: true});
    onDelete(post.id);
  }

  cancel(e){
    e.preventDefault();
    this.props.cancel();
  };

  render() {
    const { post } = this.props;
    const { submitting } = this.state;

    const control_content = (submitting) ? (
      <div className="submitting">
        <i className="fa fa-spinner fa-spin fa-2x"/>
      </div>
    ) : (
      <div className="control">
        <div className="confirm">Really delete this post?</div>
        <div className="d-flex justify-content-between flex-row p-2">
          <Button color="danger" onClick={this.handleConfirm}>Yes</Button>
          <Button color="secondary" onClick={this.cancel}>No</Button>
        </div>
      </div>
    );

    return (
      <div className="delete-post">
        {control_content}
        <Post post={post}/>
      </div>
    );
  }
};

