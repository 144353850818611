import moment from 'moment-timezone';
import { arrayToIndexedObject } from 'app/utils';


/**
 * Returns the date/time in the local timezone, converted from the server
 * timezone
 */
export function convertTZ(source, tz) {
  return moment.tz(source, 'UTC').tz(tz);
};


/**
 * Returns a document.title string from components
 *
 * @param {String} title
 * @param {String} context
 * @param {String} env
 * @return {String}
 */
export function make_title(title, context='', env='') {
  return (context ? `${context} - ` : '') + title + (env ? ` (${env})` : '');
}


/**
 * Convert array of posts, including system posts, to audited array of posts.
 *   Adds "status" and "audits" attributes to posts.
 *   Delete the message of deleted posts.
 */
export function postsToAudited(posts) {

  const reId = /\#(\d+)/;

  let postsObj = arrayToIndexedObject(posts);

  for (const post of posts) {
    if (post.system) {
      const action = post.message.slice(0,7).toUpperCase();
      const target = parseInt(post.message.match(reId)[1], 10);
      let targetPost = postsObj[target] || {};

      if (action === 'DELETED') {
        // @TODO Reconsituting deleted posts.
        // This is necessary because deleted posts are literally deleted on
        // the server. In the future, they will not be.
        targetPost = {
          id: target,
          user: post.user,
          topic_id: post.topic_id,
          message: '',
          created_at: post.created_at,
          updated_at: post.updated_at,
          system: false,
        }
      }
      targetPost.state = action;
      targetPost.audits = targetPost.audits || [];
      targetPost.audits.push({
        id: post.id,
        user_id: post.user_id,
        created_at: post.created_at,
        action: action
      });
      // Requried in the case of deleted posts being reconstituted.
      postsObj[target] = targetPost;
    }
  }
  return postsObj;
}