import React from 'react';
import {connect} from 'react-redux';
import PostHeader from 'post/ui/PostHeader';


export function PostHeaderContainer(props) {
  return (
    <PostHeader {...props}/>
  );
};

function select(state, ownProps) {
  const {user} = ownProps.post;
  return {
    user: state.users[user.id],
    auth: state.config.auth,
    editing: !!state.app.editing
  }
}

export default connect(select)(PostHeaderContainer);