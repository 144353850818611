import React from 'react';
// import Slider from 'react-slick';
import {connect} from 'react-redux';
import {getAPIStatus} from 'app/api/hyper-redux';
import {fetchUpdates} from 'actions';
import PostsPageNav from 'posts/ui/PostsPageNav';

export class PostsPageNavContainer extends React.Component {

  render() {
    return <PostsPageNav {...this.props} />;
  }
};


function mapStateToProps(state, ownProps) {
  return {
    loading: getAPIStatus('fetch_updates')
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    refresh: () => {dispatch(fetchUpdates())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsPageNavContainer);
