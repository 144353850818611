import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import SiteContainer from 'site/data/SiteContainer';
import TopicsContainer from 'data/TopicsContainer';
import PostsPageContainer from 'posts/data/PostsPageContainer';


export default (
  <Router basename='/'>
    <SiteContainer>
      <Route exact path="/topics/" component={ TopicsContainer } />
      <Route path="/topics/:topicId/posts" component={ PostsPageContainer } />



      <Route path="/chats/:topicId/posts/" render={ (props) => (
        <Redirect to={`/topics/${props.match.params.topicId}/posts`}/>
      )} />
    </SiteContainer>
  </Router>
);
