import React from 'react';
import {connect} from 'react-redux';
import {sortTopicsByUpdatedAt} from 'api/hyper-redux';
import TopicList from 'ui/TopicList';

export class TopicListContainer extends React.Component {

  render() {
    return (
      <div>
        <TopicList topics={this.props.topics} />
      </div>
    );
  }
};

function select(state, props) {
  return {
    topics: sortTopicsByUpdatedAt(state.topics)
  };
}

export default connect(select)(TopicList);
