import React from 'react';
import {connect} from 'react-redux';
import CarouselPost from 'carousel-post/ui/CarouselPost';


export class CarouselPostContainer extends React.Component {

  render() {
    const {now} = this.props;
    return (
      <CarouselPost now={now} />
    );
  };
};

const select = (state, ownProps) => {
  const {now} = state.app;
  return { now };
};

export default connect(select)(CarouselPost);