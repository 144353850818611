import React from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import PostsPageNavContainer from 'posts/data/PostsPageNavContainer';
import PostsList from 'posts/ui/PostsList';
import CreatePostForm from 'post/data/CreatePostForm';
import TopicMembers from 'posts/ui/TopicMembers';
import { POSTS_NUM_TO_LOAD } from 'constants/index';


const TEXT_LOAD_MORE_POSTS = 'Load more posts!';


export default class PostsPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleFetchPosts = this.handleFetchPosts.bind(this);
  }

  handleFetchPosts(e) {
    const {topic, posts, fetchPosts} = this.props;
    e.preventDefault();
    const created_at = posts.length > 0
      ? posts[posts.length-1].created_at
      : moment().format();
    fetchPosts(topic.id, created_at, POSTS_NUM_TO_LOAD);
  }

  render() {
    const { topic, posts, loading } = this.props;

    let loadMorePostsButton;
    if (loading) {
      loadMorePostsButton = (
        <Button size="lg" block disabled>
          <i className="fa fa-spin fa-spinner"/>
        </Button>
      );
    }
    else {
      loadMorePostsButton = (
        <Button size="lg" block onClick={this.handleFetchPosts}>
          {TEXT_LOAD_MORE_POSTS}
        </Button>
      );
    }

    return (
      <div className="chat-page container-fluid">
        <div className="row no-gutters chat-header">
          <div className="col">
            <PostsPageNavContainer topic={ topic }/>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-sm-7" id="post-list-app">
            <span className="post-form">
              <CreatePostForm topicId={topic.id}/>
            </span>
            <PostsList topic={topic} posts={posts} />
            {loadMorePostsButton}
          </div>
          <div className="col-sm-5 hidden-xs-down">
            <TopicMembers topic={ topic } />
          </div>
        </div>
      </div>
    );
  };
};
