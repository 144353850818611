// App Imports
import { getStore } from 'store';
import { getKey, indexedObjectToArray } from 'app/utils';


/**
 * Returns whether the API is engaged in a POST operation for the given
 * category / id combo
 */
export function getAPIStatus(category, id='None') {
  return getKey(getStore().getState().api.status, `${category}.${id}`, false);
};


/**
 * Hydrates an array of IDs from the specified pool of objects.
 */
export function hydrate(list, objects, key=undefined) {
  let hydrated = [];
  for (let item of list) {
    if (key) {
      item = item[key];
    }
    if (objects[item]) {
      hydrated.push(objects[item]);
    } else {
      throw `Object not found for key: ${item}`;
    }
  }
  return hydrated;
};

/**
 * @returns Array
 */
export function filterPostsByTopicId(posts, topicId) {
  posts = indexedObjectToArray(posts)
    .filter((post) => {
      return post.topic_id === topicId && post.system === false;
    })
    .sort((a, b) => {
      return (a.created_at < b.created_at) ? 1 : -1;
    });

  return posts;
};

/**
 * @returns Array sorted by updated_at field.
 */
export function sortTopicsByUpdatedAt(topics) {
  return indexedObjectToArray(topics).sort((a, b) => {
    return (a.updated_at < b.updated_at) ? 1 : -1;
  });
};

/**
 * Returns the list of users who granted karma to the specified post
 */
export function getPostBenefactors(postId) {
  const state = getStore().getState();
  let benefactors = [];
  let karmas = state.mapping.posts_to_karma[postId];
  if (karmas) {
    karmas = hydrate(karmas, state.karma);
    for (let karma of karmas) {
      benefactors.push(karma.benefactor_id);
    }
    benefactors = hydrate(benefactors, state.users);
  }
  return benefactors;
}
