import axios from 'axios';
import moment from 'moment-timezone';


const options = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  xsrfCookieName: 'xt',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  baseURL: '/api/v1'
};
const ax = axios.create(options);


export function fetchLaunchData(options={}) {
  return ax.get('/view/uncached/', options);
};

/**
 * string timestamp of the most recent update request.
 */
export function fetchUpdatesSince(timestamp, options={}) {
  return ax.get(`/view/updates/?since=${timestamp}`, options);
};

export function fetchTopics(options={}) {
  return ax.get('/topics/', options);
};

export function fetchPosts(topicId, before, count=5, options={}) {
  return ax.get(`/topics/${topicId}/posts/?count=${count}&before=${before}`, options);
};

/**
 * Fetch all karmas related to this post.
 */
export function fetchKarmaForPost(postId, options={}) {
  return ax.get(`/posts/${postId}/karma`, options);
};

/**
 * Increment karma total by one for the specified post.
 */
export function addKarma(postId, options={}) {
  return ax.post(`/posts/${postId}/karma/add`, undefined, options);
};

/**
 * Create new post
 */
export function createPost(topicId, message, options={}) {
  return ax.post(`/topics/${topicId}/posts/create`, {message}, options);
};

/**
 * Update post
 */
export function updatePost(postId, message, options={}) {
  return ax.post(`/posts/${postId}/update`, {message}, options);
};

/**
 * Delete post
 */
export function deletePost(postId, options={}) {
  return ax.post(`/posts/${postId}/delete`, undefined, options);
};
