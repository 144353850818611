import PostForm from 'post/ui/PostForm';
import { reduxForm, reset } from 'redux-form';
import { FORM_POST_CREATE } from 'constants/strings';


function afterSubmitSuccess(result, dispatch) {
  dispatch(reset(FORM_POST_CREATE));
}


export default reduxForm({ form: FORM_POST_CREATE, onSubmitSuccess: afterSubmitSuccess })(PostForm);
