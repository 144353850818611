import React from 'react';
import { Link } from 'react-router-dom';
import CarouselPostListContainer from 'carousel-post/data/CarouselPostListContainer';

export default class Topic extends React.Component {

  render() {
    const topic = this.props.topic;

    return (
      <div className="topic">
          <div className="title">
            <Link to={`/topics/${topic.id}/posts`}>
              <div>{ topic.name }</div>
            </Link>
        </div>
        <CarouselPostListContainer topicId={topic.id}/>
      </div>
    );
  };
};
