import React from 'react';


export default class TopicsNavBar extends React.Component {

  render() {
    const { loading, refresh } = this.props;

    let buttonProps = {};
    let spinnerClass = 'fa fa-fw fa-refresh ';

    if (loading) {
      buttonProps = {
        ...buttonProps,
        disabled: 'disabled'
      }
      spinnerClass += ' fa-spin';
    } else {
      buttonProps = {
        ...buttonProps,
        onClick: refresh
      }
    }

    return (
      <div className="topic-box">
        <div className="topic-box-navbar">
          <span className="nav-buttons button-refresh">
            <button type="button" className="btn btn-sm btn-secondary" {...buttonProps}>
              <i className={spinnerClass}/>
            </button>
          </span>
          <span className="nav-buttons button-new-chat">
            <a role="button" className="btn btn-sm btn-secondary" href="/chats/create/">
              <i className="fa fa-plus fa-fw"/>
            </a>
          </span>
        </div>
      </div>
    );
  }
};
